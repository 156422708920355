<template>

  <div class="rounded green lighten-4 pa-3">
  <v-row>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.sewa"
          label="Sewa"
          prefix="Rp"
          dense
          hide-details
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.biayaSewa"
          label="Biaya Sewa"
          prefix="Rp"
          dense
          hide-details
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.akuisisiPembelian"
          label="Akuisisi Pembelian"
          prefix="Rp"
          dense
          hide-details
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.hargaPembelian"
          label="Harga Pembelian"
          prefix="Rp"
          dense
          hide-details
      ></v-text-field>
    </v-col>

  </v-row>

</div>

</template>

<script>
import FPotensi from "@/models/f-potensi";

export default {
  name: "FPotensiKawasanKepemilikan",
  props:{
    kawasan: Boolean,
    itemModified: new FPotensi(),
    itemsFDivision: [],
  },
  data(){
    return{
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

    }
  },
  computed:{
  },
  methods:{

    lookupFDivision (fdivisionBean) {
      // console.log(fdivisionBean)
      // console.log(JSON.stringify(this.itemsFDivision))
      const str = this.itemsFDivision.filter(x => x.id === fdivisionBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

  }


}
</script>

<style scoped>

</style>