<template>
<div>
  <v-row>
    <v-col
        cols="12"
        sm="2"
        md="2"
    >
      <v-text-field
          v-model="itemModified.kode1"
          label="Kode"
          :rules="rulesNotEmtpy"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="8"
        md="8"
    >
      <v-text-field
          v-if="lang==='id'"
          v-model="itemModified.kode2"
          :rules="rulesNotEmtpy"
          label="Nama Potensi/Peluang'"
          dense
      ></v-text-field>
      <v-text-field
          v-if="lang==='en'"
          v-model="itemModified.kode2En"
          :rules="rulesNotEmtpy"
          label="Name of Potential/Opportunity"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="2"
        md="2"
    >
      <v-switch
          v-model="itemModified.statusActive"
          :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
          x-small
          class="pa-3"
      ></v-switch>
    </v-col>
  </v-row>

  <v-row>
    <v-col
        cols="12"
        sm="10"
        md="10"
    >
      <v-textarea
          v-if="lang==='id'"
          v-model="itemModified.description"
          label="Deskripsi"
          dense
          rows="7"
          auto-grow
          outlined
      ></v-textarea>
      <v-textarea
          v-if="lang==='en'"
          v-model="itemModified.descriptionEn"
          label="Description"
          dense
          rows="7"
          auto-grow
          outlined
      ></v-textarea>
    </v-col>
  </v-row>
  <v-row>
    <v-col
        cols="12"
        sm="8"
        md="8"
    >
      <v-textarea
          v-if="lang==='id'"
          v-model="itemModified.bidangUsaha"
          label="Bidang Usaha (Potensi)"
          dense
          rows="5"
          auto-grow
          outlined
      ></v-textarea>
      <v-textarea
          v-if="lang==='en'"
          v-model="itemModified.bidangUsahaEn"
          label="Business Field (Potential)"
          dense
          rows="5"
          auto-grow
          outlined
      ></v-textarea>
    </v-col>
  </v-row>

  <v-row>
    <v-col
        cols="12"
        sm="6"
        md="6"
    >
      <v-autocomplete
          v-model="itemModified.fdivisionBean"
          :items="itemsFDivision"
          :rules="rulesNotEmtpy"
          item-value="id"
          item-text="description"
          auto-select-first
          small-chips
          deletable-chips
          color="blue-grey lighten-2"
          :label="lang==='id'? 'Pemilik Data (Kab/Kota)': 'Data Owner (District/City)'"
          :hint="lang==='id'?`Pemilik Data (Kab/Kota): ${lookupFDivision(itemModified.fdivisionBean).description}` : `Data Owner (District/City) : ${lookupFDivision(itemModified.fdivisionBean).description}`"
          persistent-hint
          dense
      ></v-autocomplete>
    </v-col>
  </v-row>

  <v-row>
    <v-col
        cols="12"
        sm="6"
        md="6"
    >
      <v-text-field
          v-model="itemModified.address"
          :label="lang==='id'? 'Alamat' : 'Address'"
          dense
      ></v-text-field>
    </v-col>

    <v-col
        cols="12"
        sm="5"
        md="5"
    >
      <v-autocomplete
          v-model="itemModified.fsubAreaBean"
          :items="computedItemsFSubArea"
          item-value="id"
          item-text="descriptionExt"
          :rules="rulesNotEmtpy"
          auto-select-first
          dense
          small-chips
          deletable-chips
          color="blue-grey lighten-2"
          :label="lang==='id'? 'Kode Kecamatan': 'Sub-district code'"
          persistent-hint
          @change="updateKecamatanKota"
      >
        <template v-slot:selection="data">
          <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="itemModified.fsubAreaBean =undefined"
          >
            {{ data.item.description }}
            -<span class="caption ml-2 blue--text text--darken-1"> {{ lookupFArea(data.item.fareaBean).description }} </span>

          </v-chip>
        </template>

        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title>{{data.item.description}}</v-list-item-title>
              <v-list-item-subtitle>
                <span class="font-weight-light caption"> {{ lookupFArea(data.item.fareaBean).description }} </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>

    </v-col>

    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.district"
          :label="lang==='id'? 'Kecamatan': 'Sub-district'"
          dense
          disabled
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.city"
          :label="lang==='id'? 'Kab-Kota' : 'District/City'"
          dense
          disabled
      ></v-text-field>
    </v-col>

  </v-row>

  <v-row>

  </v-row>

  <v-row>
    <v-col
        cols="12"
        sm="4"
        md="4"
        class="red lighten-4 rounded-lg mt-2 mb-2"
    >
      <v-select
          v-model="itemModified.jenis"
          :items="itemsJenisPotensi"
          item-value="description"
          item-text="description"
          :label="lang==='id'? 'Jenis Potensi, Peluang, Kawasan': 'Types of Potential, Opportunities, Areas'"
          dense
          :rules="rulesNotEmtpy"
      ></v-select>
    </v-col>

    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-autocomplete
          v-model="itemModified.fsectorTypeBean"
          :items="itemsFSectorType"
          item-value="id"
          item-text="description"
          :rules="rulesNotEmtpy"
          auto-select-first
          dense
          small-chips
          deletable-chips
          color="blue-grey lighten-2"
          :label="lang==='id'? 'Pilih Sector': 'Choose Sector'"
          :hint="`Sector : ${lookupFSectorType(itemModified.fsectorTypeBean).kode1}`"
          persistent-hint
          single-line
      ></v-autocomplete>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
        v-if="! kawasan"
    >
      <v-autocomplete
          v-model="itemModified.fkomoditiTypeBean"
          :items="itemsFKomoditiType"
          item-value="id"
          item-text="description"
          auto-select-first
          dense
          small-chips
          deletable-chips
          color="blue-grey lighten-2"
          :label="lang==='id'? 'Pilih Komoditi': 'Choose Komodity'"
          :hint="`Komoditi : ${lookupFKomoditiType(itemModified.fkomoditiTypeBean).kode1}`"
          persistent-hint
          single-line
      ></v-autocomplete>
    </v-col>
    <v-col
        sm="1"
        md="1"
        v-if="! kawasan"
    >
      <v-switch
          v-model="itemModified.remarkBool1"
          :label="itemModified.remarkBool1?'Perpres 88':'Belum Perpres'"
          x-small
      ></v-switch>
    </v-col>
  </v-row>

  <v-row class="grey lighten-3 mt-2">
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.tahun"
          :label="lang==='id'? 'Tahun': 'Year'"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-if="! kawasan"
          v-model="itemModified.nilaiInvestasi"
          :label="lang==='id'? 'Nilai Investasi' : 'Investment Value'"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
        v-if="! kawasan"
    >
      <v-text-field
          v-model="itemModified.npv"
          label="NPV"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="3"
        md="3"
        v-if="! kawasan"
    >
      <v-text-field
          v-model="itemModified.irr"
          label="IRR %"
          dense
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row class="grey lighten-3">
    <v-col
        cols="12"
        sm="3"
        md="3"
        v-if="! kawasan"
    >
      <v-text-field
          v-model="itemModified.payBackPeriode"
          label="PayBack Periode"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="4"
        md="4"
    >
      <v-text-field
          prepend-inner-icon="mdi-google-maps"
          v-model="itemModified.lat"
          label="Latitude"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="4"
        md="4"
    >
      <v-text-field
          prepend-inner-icon="mdi-google-maps"
          v-model="itemModified.lon"
          label="Longitude"
          dense
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row class="blue lighten-1">
    <v-col
        cols="12"
        sm="3"
        md="3"
    >
      <v-text-field
          v-model="itemModified.namaKontak1"
          :label="lang==='id'? 'Nama Kontak1' : 'Contact Name1'"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="7"
        md="7"
    >
      <v-text-field
          v-model="itemModified.alamatKontak1"
          :label="lang==='id'? 'Alamat Kontak1' : 'Contact Address'"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="2"
        md="2"
    >
      <v-text-field
          v-model="itemModified.kotaKontak1"
          :label="lang==='id'? 'Kota Kontak1' : 'City Contact'"
          dense
      ></v-text-field>
    </v-col>
  </v-row>

  <v-row class="blue lighten-1">
    <v-col
        cols="12"
        sm="4"
        md="4"
    >
      <v-text-field
          prepend-inner-icon="mdi-email"
          v-model="itemModified.emailKontak1"
          label="Email Kontak1"
          dense
      ></v-text-field>
    </v-col>
    <v-col
        cols="12"
        sm="4"
        md="4"
    >
      <v-text-field
          prepend-inner-icon="mdi-phone-classic"
          v-model="itemModified.phoneKontak1"
          label="Telp Kontak1"
          dense
      ></v-text-field>
    </v-col>
  </v-row>
</div>
</template>

<script>
import FPotensi from "@/models/f-potensi";
import EPotensiType, {EPotensiTypes} from "@/models/e-potensi-type";

export default {
  name: "FPotensiDeskripsi",
  props:{
    kawasan: Boolean,
    itemModified: new FPotensi(),
    itemsFDivision: [],
    itemsFSectorType: [],
    itemsFKomoditiType: [],
    itemsFArea: [],
    itemsFSubArea: []
  },
  data(){
    return{
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],

      EPotensiTypes: EPotensiTypes,
    }
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    computedItemsFSubArea(){
      return this.itemsFSubArea.map( item =>{
            item.descriptionExt = `${item.description } ${this.lookupFArea(item.fareaBean).description}`
            return item
        }
      )
    },
    itemsJenisPotensi(){
      let listJenis = [EPotensiType.IPRO, EPotensiType.POTENSI, EPotensiType.PELUANG]
      if (this.kawasan){
        listJenis = [EPotensiType.KPI, EPotensiType.KIH, EPotensiType.KI, EPotensiType.KEK]
      }
      return listJenis
    }
  },
  methods:{
    updateKecamatanKota(){
      const fSubArea =  this.lookupFSubArea(this.itemModified.fsubAreaBean)
      this.itemModified.district = fSubArea.description
      const fArea = this.lookupFArea(fSubArea.fareaBean)
      this.itemModified.city = fArea.description
    },

    lookupFDivision (fdivisionBean) {
      // console.log(fdivisionBean)
      // console.log(JSON.stringify(this.itemsFDivision))
      const str = this.itemsFDivision.filter(x => x.id === fdivisionBean)

      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.itemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFKomoditiType (fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(x => x.id===fkomoditiTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const str = this.itemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFArea (fareaBean) {
      const str = this.itemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

  }


}
</script>

<style scoped>

</style>