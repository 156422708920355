export default class FKegiatanFile {
  constructor(
    id,
    fpotensiBean,
    fileName,
    description,

    /**
     * FILE
     * IMAGE
     * VIDEO
     * SPACIAL MAIN
     * SPACIAL EXT
     */
    fileType,
    /**
     * 1. POT INFOMEMO
     * 2. POT GALLERY
     * 3. POT VIDIO
     */
    potensiType,

    flag,
    remark1,
    remark2,
    selected,

    created,
    modifiedBy
  ) {
    this.id = id;
    this.fpotensiBean = fpotensiBean;
    this.fileName = fileName;
    this.description = description;

    this.fileType = fileType;
    this.potensiType = potensiType;

    this.flag = flag;
    this.remark1 = remark1;
    this.remark2 = remark2;

    this.selected = selected;

    this.created = created;
    this.modifiedBy = modifiedBy;
  }

}
