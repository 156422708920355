<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      fullscreen
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ titleComputed }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'en'"> NEW</span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'id'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items class="mx-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    icon
                    class="px-5"
                    x-small
                  >
                    <v-icon color="black" v-if="false">mdi-translate</v-icon>
                    <v-avatar size="18" class="elevation-1">
                      <v-img :src="getFlagImage(lang)"></v-img>
                    </v-avatar>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in menu_language"
                    :key="index"
                    dense
                  >
                    <v-list-item-title>
                      <v-avatar size="22" class="elevation-1">
                        <v-img :src="getFlagImage(item.id)"></v-img>
                      </v-avatar>
                      <v-btn
                        small
                        plain
                        elevation="0"
                        @click="languageChange(item.id)"
                      >
                        {{ item.description }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                {{ lang === "id" ? "Simpan" : "Save" }}
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <template>
                  <v-col cols="12" md="12">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 10 : 1"
                        :class="[
                          { 'on-hover': hover },
                          hover
                            ? 'card-hover-opacity'
                            : 'card-not-hover-opacity',
                        ]"
                      >
                        <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          height="300px"
                        >
                          <v-card-title class="text-h6 white--text fill-height">
                            <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                            >
                              <v-spacer />
                              <div class="align-self-center">
                                <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                                >
                                  <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                  >
                                    mdi-upload
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-container>

            <v-container>
              <FPotensiCasousel
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                :kawasan="kawasan"
                @retrieveFiles="retrieveFiles"
              ></FPotensiCasousel>
            </v-container>
          </v-card-title>

          <v-card-text>
            <v-container>
              <FPotensiDeskripsi
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :itemsFSectorType="itemsFSectorType"
                :itemsFKomoditiType="itemsFKomoditiType"
                :itemsFArea="itemsFArea"
                :itemsFSubArea="itemsFSubArea"
                :kawasan="kawasan"
              ></FPotensiDeskripsi>
            </v-container>

            <v-container v-if="kawasan">
              <FPotensiKawasanUkuran
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanUkuran>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanKepemilikan
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanKepemilikan>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanBangunan
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanBangunan>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanJalan
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanJalan>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanUtilitas
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanUtilitas>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanTelekomunikasi
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanTelekomunikasi>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanJarakTerdekat
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanJarakTerdekat>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanTenant
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanTenant>
            </v-container>
            <v-container v-if="kawasan">
              <FPotensiKawasanKriteriaKih
                :itemModified="itemModified"
                :itemsFDivision="itemsFDivision"
                :kawasan="kawasan"
              ></FPotensiKawasanKriteriaKih>
            </v-container>

            <v-container>
              <FPotensiInfomemo
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                :kawasan="kawasan"
                @retrieveFiles="retrieveFiles"
              ></FPotensiInfomemo>
            </v-container>
            <v-container>
              <FPotensiGallery
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                :kawasan="kawasan"
                @retrieveFiles="retrieveFiles"
              ></FPotensiGallery>
            </v-container>

            <v-container>
              <FPotensiMp4
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                :kawasan="kawasan"
                @retrieveFiles="retrieveFiles"
              ></FPotensiMp4>
            </v-container>

            <v-container>
              <FPotensiSpasialMain
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                @retrieveFiles="retrieveFiles"
              ></FPotensiSpasialMain>
            </v-container>
            <v-container>
              <FPotensiSpasialExt
                :itemModified="itemModified"
                :itemsFile="itemsFile"
                :kawasan="kawasan"
                @retrieveFiles="retrieveFiles"
              ></FPotensiSpasialExt>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="translateForm"
              class="hidden-sm-and-down mr-4"
              :disabled="
                lang !== 'en' &&
                (itemModified.id !== 0 || itemModified.id !== null)
              "
            >
              {{ lang === "id" ? "Terjemahkan" : "Translate" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Batal" : "Cancel" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down mr-10"
            >
              {{ lang === "id" ? "Simpan" : "Save" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>
      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FPotensiService from "../../../services/apiservices/f-potensi-service";
import FPotensiFileService from "../../../services/apiservices/f-potensi-file-service";

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FPotensi from "../../../models/f-potensi";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../../components/utils/UploadImageDialog";
import FPotensiGallery from "@/components/admin_potensi/potensi/FPotensiGallery";
import FPotensiDeskripsi from "@/components/admin_potensi/potensi/FPotensiDeskripsi";
import FPotensiInfomemo from "@/components/admin_potensi/potensi/FPotensiInfomemo";
import FPotensiMp4 from "@/components/admin_potensi/potensi/FPotensiMp4";
import FPotensiSpasialMain from "@/components/admin_potensi/potensi/FPotensiSpasialMain";
import FPotensiSpasialExt from "@/components/admin_potensi/potensi/FPotensiSpasialExt";
import FPotensiKawasanUkuran from "@/components/admin_potensi/potensi/FPotensIKawasanUkuran";
import FPotensiKawasanKepemilikan from "@/components/admin_potensi/potensi/FPotensIKawasanKepemilikan";
import FPotensiKawasanBangunan from "@/components/admin_potensi/potensi/FPotensIKawasanBagunan";
import FPotensiKawasanJalan from "@/components/admin_potensi/potensi/FPotensIKawasanJalan";
import FPotensiKawasanUtilitas from "@/components/admin_potensi/potensi/FPotensIKawasanUtilitas";
import FPotensiKawasanTelekomunikasi from "@/components/admin_potensi/potensi/FPotensIKawasanTelekomunikasi";
import FPotensiKawasanJarakTerdekat from "@/components/admin_potensi/potensi/FPotensIKawasanJarakTerdekat";
import FPotensiKawasanTenant from "@/components/admin_potensi/potensi/FPotensIKawasanTenant";
import FPotensiKawasanKriteriaKih from "@/components/admin_potensi/potensi/FPotensIKawasanKriteriaKih";
import FPotensiCasousel from "@/components/admin_potensi/potensi/FPotensiCarousel";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";

export default {
  components: {
    FPotensiCasousel,
    FPotensiKawasanKriteriaKih,
    FPotensiKawasanTenant,
    FPotensiKawasanJarakTerdekat,
    FPotensiKawasanTelekomunikasi,
    FPotensiKawasanUtilitas,
    FPotensiKawasanJalan,
    FPotensiKawasanBangunan,
    FPotensiKawasanKepemilikan,
    FPotensiKawasanUkuran,
    FPotensiSpasialExt,
    FPotensiMp4,
    FPotensiInfomemo,
    FPotensiDeskripsi,
    FPotensiGallery,
    FPotensiSpasialMain,
    CloseConfirmDialog,
    UploadImageDialog,
  },
  props: {
    kawasan: Boolean,
    formMode: String,
    itemsFDivision: [],
    itemsFSectorType: [],
    itemsFKomoditiType: [],
    itemsJenis: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  data() {
    return {
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.jpg" },
      ],

      title: "Potensi",
      snackBarMesage: "",
      snackbar: false,

      imageOverlay: false,

      dialogLoading: false,
      loading: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FPotensi(0, "", ""),
      selectFDivision: { id: 0, kode1: "", description: "" },

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      itemsFile: [],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
    titleComputed() {
      if (this.kawasan) {
        return "KAWASAN";
      } else {
        return "POTENSI/IPRO";
      }
    },
  },
  watch: {},

  methods: {
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },
    languageChange(value) {
      // console.log(value)
      this.lang = value;
      this.$i18n.locale = this.lang;
    },
    translateForm() {
      this.snackBarMesage = "";
      this.dialogLoading = true;
      this.loading = true;
      let itemFPotensiTranslate = [];
      let emptyValue = "-";
      if (
        this.itemModified.kode2 !== null &&
        this.itemModified.kode2 !== undefined &&
        this.itemModified.kode2 !== "" &&
        this.itemModified.kode2 !== "-" &&
        (this.itemModified.kode2En === null ||
          this.itemModified.kode2En === undefined ||
          this.itemModified.kode2En === "" ||
          this.itemModified.kode2En === "-")
      ) {
        itemFPotensiTranslate.push(this.itemModified.kode2);
      } else {
        itemFPotensiTranslate.push(emptyValue);
      }
      if (
        this.itemModified.description !== null &&
        this.itemModified.description !== undefined &&
        this.itemModified.description !== "" &&
        this.itemModified.description !== "-" &&
        (this.itemModified.descriptionEn === null ||
          this.itemModified.descriptionEn === undefined ||
          this.itemModified.descriptionEn === "" ||
          this.itemModified.descriptionEn === "-")
      ) {
        itemFPotensiTranslate.push(this.itemModified.description);
      } else {
        itemFPotensiTranslate.push(emptyValue);
      }
      if (
        this.itemModified.bidangUsaha !== null &&
        this.itemModified.bidangUsaha !== undefined &&
        this.itemModified.bidangUsaha !== "" &&
        this.itemModified.bidangUsaha !== "-" &&
        (this.itemModified.bidangUsahaEn === null ||
          this.itemModified.bidangUsahaEn === undefined ||
          this.itemModified.bidangUsahaEn === "" ||
          this.itemModified.bidangUsahaEn === "-")
      ) {
        itemFPotensiTranslate.push(this.itemModified.bidangUsaha);
      } else {
        itemFPotensiTranslate.push(emptyValue);
      }
      // console.log(JSON.stringify(itemFPotensiTranslate))
      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(
        encodeURIComponent(JSON.stringify(itemFPotensiTranslate))
      ).then(
        (response) => {
          try {
            let itemFPotensiTranslateResponse = response.data.translation
              .replaceAll("+", " ")
              .replaceAll("=", " ")
              .trim();
            let itemFPotensiTranslateResponseParsed = JSON.parse(
              itemFPotensiTranslateResponse
            );
            if (
              (this.itemModified.kode2En === null ||
                this.itemModified.kode2En === undefined ||
                this.itemModified.kode2En === "" ||
                this.itemModified.kode2En === "-") &&
              itemFPotensiTranslateResponseParsed[0] != "-"
            ) {
              this.itemModified.kode2En =
                itemFPotensiTranslateResponseParsed[0];
            }
            if (
              (this.itemModified.descriptionEn === null ||
                this.itemModified.descriptionEn === undefined ||
                this.itemModified.descriptionEn === "" ||
                this.itemModified.descriptionEn === "-") &&
              itemFPotensiTranslateResponseParsed[1] != "-"
            ) {
              this.itemModified.descriptionEn =
                itemFPotensiTranslateResponseParsed[1];
            }
            if (
              (this.itemModified.bidangUsahaEn === null ||
                this.itemModified.bidangUsahaEn === undefined ||
                this.itemModified.bidangUsahaEn === "" ||
                this.itemModified.bidangUsahaEn === "-") &&
              itemFPotensiTranslateResponseParsed[2] != "-"
            ) {
              this.itemModified.bidangUsahaEn =
                itemFPotensiTranslateResponseParsed[2];
            }

            FPotensiService.updateFPotensi(this.itemModified).then(() => {
              console.log("Update Oke");
            });
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data berhasil diterjemahkan";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "Data successfully translated";
            }
          } catch (error) {
            this.dialogLoading = false;
            this.loading = false;
            this.snackbar = true;
            if (this.lang === "id") {
              this.snackBarMesage = "Data yang diterjemahkan melebihi batas";
            }
            if (this.lang === "en") {
              this.snackBarMesage = "The translated data exceeds the limit";
            }
            console.log(error);
          }
          // console.log(response.data.translation)
        },
        (error) => {
          // console.log(" Hasil Error: " + item.description)
          console.log(error.data);
        }
      );
    },
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fsectorTypeBean !== 0 &&
        this.itemModified.fsubAreaBean !== 0 &&
        this.itemModified.fdivisionBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi, Sektor dan Desa harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      //Hapus Dulu yang lama
      // console.log(this.itemModified.avatarImage)

      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          () => {
            // console.log(response.data)
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FPotensi(0, "", "")),
          (this.itemModified = new FPotensi(0, "", "")),
          (this.selectedIndex = -1);
      }
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FPotensiService.updateFPotensi(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FPotensiService.createFPotensi(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FPotensiService.createFPotensi(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          // this.initializeEditMode(response.data)
          this.$emit("update:formMode", FormMode.EDIT_FORM);
          this.itemModified.id = response.data.id;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FPotensiService.updateFPotensi(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = "";

      FPotensiService.getFPotensiById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;

          this.retrieveFiles();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    retrieveFiles() {
      FPotensiFileService.getAllFPotensiFileByParent(this.itemModified.id).then(
        (response) => {
          // console.log(JSON.stringify(response.data))
          this.itemsFile = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return [0];
      } else {
        return "-";
      }
    },

    lookupFSectorType(fsectorTypeBean) {
      const str = this.itemsFSectorType.filter((x) => x.id === fsectorTypeBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    lookupFKomoditiType(fkomoditiTypeBean) {
      const str = this.itemsFKomoditiType.filter(
        (x) => x.id === fkomoditiTypeBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
  },
};
</script>

<style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>