<template>
  <div class="mt-2">
    <v-card>
      <v-card-title> MP4 </v-card-title>
      <v-card-text>
        <v-container class="text-center">
          <v-row class="fill-height" align="center" justify="center">
            <v-col
              v-for="(item, i) in itemsFileFiltered"
              :key="i"
              class="d-flex child-flex"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <template v-if="true">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="align-self-center"
                    :elevation="hover ? 10 : 0"
                    :class="[
                      { 'on-hover': hover },
                      hover ? 'card-hover-opacity' : 'card-not-hover-opacity',
                    ]"
                  >
                    <div v-if="item.fileType === 'IMAGE'">
                      <v-img
                        :lazy-src="lookupImageUrlLazy(item)"
                        :src="lookupImageUrl(item)"
                      >
                        <v-card-title class="text-h6 white--text fill-height">
                          <v-row
                            class="fill-height flex-column"
                            justify="space-between"
                          >
                            <v-spacer />
                            <div class="align-self-center">
                              <v-btn
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                icon
                                large
                                dark
                                outlined
                                @click="deleteDialogShow(item)"
                              >
                                <v-icon
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  large
                                >
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-row>
                        </v-card-title>
                      </v-img>
                    </div>

                    <div v-else class="justify-center align-center">
                      <div>
                        <Media
                          :kind="'video'"
                          :isMuted="false"
                          :src="urlMp4File(item)"
                          :controls="true"
                          :autoplay="false"
                          :ref="'fish'"
                          :style="{ width: '350px' }"
                          @pause="handle()"
                        >
                        </Media>
                      </div>

                      <v-btn
                        icon
                        dark
                        outlined
                        @click="deleteDialogShow(item)"
                        color="red"
                      >
                        <v-icon color="red">mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </v-hover>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="text-center">
          <v-row>
            <v-col cols="12" sm="7" md="7" v-if="false">
              <v-btn color="primary" block @click="showDialogUpload"
                ><v-icon>mdi-image</v-icon> Photo
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                class="white--text"
                color="red"
                block
                @click="showDialogUploadMp4"
                ><v-icon>mdi-video</v-icon> Vidio
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <UploadImageDialog
      ref="refUploadDialog"
      @eventUploadSuccess="completeUploadSuccess"
    ></UploadImageDialog>
    <UploadMp4Dialog
      ref="refUploadDialogMp4"
      @eventUploadSuccess="completeUploadMp4Success"
    ></UploadMp4Dialog>

    <delete-confirm-dialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></delete-confirm-dialog>

    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import FPotensiFileService from "../../../services/apiservices/f-potensi-file-service";

import FPotensi from "../../../models/f-potensi";
import UploadImageDialog from "@/components/utils/UploadImageDialog";
import UploadMp4Dialog from "@/components/utils/UploadMp4Dialog";
import FPotensiFile from "../../../models/f-potensi-file";
import DeleteConfirmDialog from "@/components/utils/DeleteConfirmDialog";

import Media from "@dongido/vue-viaudio";

export default {
  components: {
    Media,
    UploadMp4Dialog,
    UploadImageDialog,
    DeleteConfirmDialog,
  },
  props: {
    itemModified: new FPotensi(),
    itemsFile: [],
  },
  data() {
    return {
      snackbar: false,
      snackBarMesage: "",

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    itemsFileFiltered() {
      return this.itemsFile
        .filter(
          (item) =>
            item.potensiType === 3 &&
            (item.fileType === "MP4" || item.fileType === "MP4")
        )
        .sort((a, b) => a.fileType > b.fileType);
      // return this.itemsFile
    },
  },
  methods: {
    handle() {
      console.log("Video paused!, playing in 2 sec...");

      setTimeout(() => {
        this.$refs.fish.play();
      }, 2000);
    },
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fsectorTypeBean !== 0 &&
        this.itemModified.fsubAreaBean !== 0 &&
        this.itemModified.fdivisionBean !== 0
      ) {
        // if (this.itemModified.id===0){
        // this.$emit('eventSaveItemWithoutClose', false)
        // console.log("masuk masih kosong")
        // }else {
        //   console.log("Sudah ada isinya")
        // }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage = "Deskripsi dan Divisi harus diisi dahulu";
        this.snackbar = true;
      }
    },
    completeUploadSuccess: function (val) {
      if (val.fileName !== "") {
        let fpotensiFileBean = new FPotensiFile(
          0,
          this.itemModified.id,
          val.fileName,
          val.description,
          "IMAGE",
          4,
          true
        );
        fpotensiFileBean.modifiedBy = this.$store.state.auth.user.username;

        FPotensiFileService.createFPotensiFile(fpotensiFileBean).then(
          (response) => {
            // console.log(response)
            this.$refs.refUploadDialog.closeDialog();
            //Just REfresh files
            this.$emit("retrieveFiles", response.dummy);
          },
          (error) => {
            console.log(error.message);
          }
        );
      }
    },
    showDialogUploadMp4() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fsectorTypeBean !== 0 &&
        this.itemModified.fsubAreaBean !== 0 &&
        this.itemModified.fdivisionBean !== 0
      ) {
        this.$refs.refUploadDialogMp4.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi, Sektor dan Desa harus diisi dahulu";
        this.snackbar = true;
      }
    },
    completeUploadMp4Success: function (val) {
      if (val.fileName !== "") {
        let fpotensiFileBean = new FPotensiFile(
          0,
          this.itemModified.id,
          val.fileName,
          val.description,
          "MP4",
          3,
          true
        );
        fpotensiFileBean.modifiedBy = this.$store.state.auth.user.username;

        FPotensiFileService.createFPotensiFile(fpotensiFileBean).then(
          (response) => {
            // console.log(response)
            this.$refs.refUploadDialogMp4.closeDialog();
            //Just REfresh files
            this.$emit("retrieveFiles", response.dummy);
          },
          (error) => {
            console.log(error.message);
          }
        );
      }
    },
    downloadFile(item) {
      // console.log(item)
      this.itemSelectedIndex = this.itemsFile.indexOf(item);
      this.itemSelected = Object.assign({}, item);

      return FileService.file_url(item.fileName);
    },
    urlMp4File(item) {
      return FileService.fileStreamMp4File(item.fileName);
    },

    deleteDialogShow(item) {
      // console.log(item)
      this.itemSelectedIndex = this.itemsFile.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.description
      );
    },

    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.itemsFile[this.itemSelectedIndex];
      FPotensiFileService.deleteFPotensiFile(deletedItem.id).then(
        () => {
          this.itemsFile.splice(this.itemSelectedIndex, 1);
          this.closeDialog();
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
      FileService.deleteImage(deletedItem.fileName).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      console.log(items);
    },
    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      this.$refs.refDeleteConfirmDialog.setDialogState(false);
      this.$refs.refFormDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPotensi());
        this.itemSelectedIndex = -1;
      });
    },

    lookupImageUrl(item) {
      console.log(item.fileName);
      if (item.fileName === undefined) {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_high(item.fileName);
      }
    },
    lookupImageUrlLazy(item) {
      // return './assets/images/no_image_available.jpeg'
      return FileService.image_url_verylow(item.fileName);
    },
    lookupImageUrlVideo(item) {
      console.log(item);
      return "./assets/images/vidio.png";
    },
  },
};
</script>

<style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  /*transition: opacity .4s ease-in-out;*/
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: red !important;
  opacity: 1;
}
</style>