<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >

      <v-card>
        <v-form  v-model="valid" ref="form">

          <v-toolbar
              dark
              color="brown"
              class="color-gradient-1"
              dense
          >
            <v-btn
                icon
                dark
                @click="closeForm"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM' ">EDIT </span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' "> BARU</span>

            <span class="ml-2 mr-2 font-weight-medium grey--text" v-show="isItemModified"> | </span>
            <v-chip
                class="ma-2"
                color="warning"
                outlined
                x-small
                v-show="isItemModified"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items >
              <v-btn
                  dark
                  text
                  @click="save"
                  :disabled="!valid || isItemModified===false"
                  class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="itemModified.description"
                      :rules="rulesNotEmtpy"
                      label="Description"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="2"
                    md="2"
                >
                  <v-switch
                      v-model="itemModified.selected"
                      :label="itemModified.selected?'Default Select':'-'"
                      x-small
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="6"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.remark1"
                      label="Line Color"
                  >
                    <template v-slot:prepend>
                      <v-tooltip
                          bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" :color="itemModified.remark1">
                            mdi-format-color-fill
                          </v-icon>
                        </template>
                        I'm a tooltip
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                    cols="6"
                    sm="4"
                    md="4"
                >
                  <v-text-field
                      v-model="itemModified.remark2"
                      label="Fill Color"
                  >
                    <template v-slot:prepend>
                      <v-tooltip
                          bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" :color="itemModified.remark2">
                            mdi-format-color-fill
                          </v-icon>
                        </template>
                        I'm a tooltip
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

           </v-container>
          </v-card-text>


          <v-card-actions>
            <v-chip
                class="ml-4"
                color="error"
                outlined
                close
                small
                v-show="formDialogOptions.errorMessage"
            >
              {{formDialogOptions.errorMessage}}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="closeForm"
                class="hidden-sm-and-down"
            >
              Batal
            </v-btn>
            <v-btn
                color="blue darken-1"
                outlined
                text
                @click="save"
                :disabled="!valid || isItemModified===false"
                class="hidden-sm-and-down"
            >
              Simpan
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>


      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>


    </v-dialog>
  </div>

</template>

<script>
import FPotensiFileService from '../../../services/apiservices/f-potensi-file-service';

import CloseConfirmDialog from "../../../components/utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FPotensiFile from "../../../models/f-potensi-file";

export default {
  components: { CloseConfirmDialog },
  props:{
    formMode: String,
  },
  data() {
    return {
      title: 'FPotensiSpasialMainDialog',
      snackBarMesage: '',
      snackbar: false,

      imageOverlay: false,

      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: '',
      itemModified: new FPotensiFile(0, '', ''),
      selectFRegion: {id: 0, kode1: '', description: '' },
      itemsFRegion: [],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],


      transparent: 'rgba(255, 255, 255, 0)',

    }
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    },

  },
  watch: {
  },

  methods: {


    showDialog(selectedIndex, item) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow
      if (selectedIndex >-1) {
        this.selectedIndex = selectedIndex
        this.initializeEditMode(item)
      }else {
        this.itemDefault =  new FPotensiFile(0, '', ''),
        this.itemModified =  new FPotensiFile(0, '', ''),
        this.selectedIndex = -1
      }
      
    },
    setDialogState(value){
      this.dialogShow =value
    },
    save(){
      if (this.isItemModified===false){
        //Close aja
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
        return
      }
      if (this.$refs.form.validate()){
        if (this.formMode===FormMode.EDIT_FORM) {
          FPotensiFileService.updateFPotensiFile(this.itemModified).then(
              () => {
                // console.log(response.data)
                this.$emit('eventFromFormDialogEdit', this.itemModified)
                this.dialogShow = false
              },
              error => {
                this.formDialogOptions.errorMessage = error.response.data.message
              }
          )
        }else {

          // FPotensiFileService.createFPotensiFile(this.itemModified).then(
          //     response =>{
          //       this.$emit('eventFromFormDialogNew', response.data)
          //       this.dialogShow = false
          //     },
          //     error => {
          //       this.formDialogOptions.errorMessage = error.response.data.message
          //     }
          // )

        }
      }
    },

    saveUpdateOnly(){
      FPotensiFileService.updateFPotensiFile(this.itemModified).then(
          () => {
          },
          error => {
            // console.log(error);
            this.formDialogOptions.errorMessage = error.response.data.message
          }
      )
    },
    closeForm(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
    initializeEditMode(item){
      // this.itemDefault = item
      // this.itemModified = item

      this.formDialogOptions.errorMessage = ''

      FPotensiFileService.getFPotensiFileById(item.id).then(
          response =>{
            // console.log(response.data)
            this.itemDefault = Object.assign({}, response.data)
            this.itemModified = response.data
          },
          error =>{
            console.log(error)
          }
      )
    },


  }

}
</script>

<style scoped>

</style>