import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";


const API_URL = ConstApiUrls.API_SERVICE_URL

class FPotensiService {


    getApiUrl() {
        return API_URL
    }
    getAllFPotensiPublic(){
        return axios.get(API_URL + `public/getAllFPotensiPublic`, { headers: authHeader() });
    }
    getAllFPotensi(){
        return axios.get(API_URL + `getAllFPotensi`, { headers: authHeader() });
    }
    getAllFPotensiByOrgLevel(){
        return axios.get(API_URL + `getAllFPotensiByOrgLevel`, { headers: authHeader() });
    }

    getAllFPotensiContainingPublic(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `public/getAllFPotensiContainingPublic?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFPotensiContainingPublicWithJenis(page, pageSize, sortBy, order, search, jenis){
        return axios.get(API_URL + `public/getAllFPotensiContainingPublicWithJenis?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}&jenis=${jenis}`, { headers: authHeader() });
    }
    getAllFPotensiContainingPublicWithCity(page, pageSize, sortBy, order, search, city){
        return axios.get(API_URL + `public/getAllFPotensiContainingPublicWithCity?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}&city=${city}`, { headers: authHeader() });
    }

    getAllFPotensiContainingPublicWithJenisExt(fpotensiFilter){
       // console.log(JSON.stringify(fpotensiFilter))
        return axios.post(API_URL + `public/getAllFPotensiContainingPublicWithJenisExt`, fpotensiFilter,{ headers: authHeader() });
    }
    
    getAllFPotensiContaining(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFPotensiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFPotensiContainingPotensi(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFPotensiContainingPotensi?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getAllFPotensiContainingKawasan(page, pageSize, sortBy, order, search){
        return axios.get(API_URL + `getAllFPotensiContainingKawasan?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }

    getAllFPotensiContainingPotensiExt(fpotensiFilter){
        // console.log(JSON.stringify(fpotensiFilter))
        return axios.post(API_URL + `getAllFPotensiContainingPotensiExt`, fpotensiFilter,{ headers: authHeader() });
    }
    getAllFPotensiContainingKawasanExt(fpotensiFilter){
        return axios.post(API_URL + `getAllFPotensiContainingKawasanExt`, fpotensiFilter,{ headers: authHeader() });
    }
    getFPotensiById(id){
        return axios.get(API_URL + `getFPotensiById/${id}`, { headers: authHeader() });
    }


    getApiPotensiContainingPubic(page, pageSize, sortBy, order, search, city, jenis){
        return axios.get(API_URL + `public/getApiPotensiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}&city=${city}&jenis=${jenis}`, { headers: authHeader() });
    }
    getApiPotensiByIdPublic(id){
        return axios.get(API_URL + `public/getApiPotensiById/${id}`, { headers: authHeader() });
    }
    getApiPotensiByIdsPublic(ids){
        return axios.get(API_URL + `public/getApiPotensiByIds/${ids}`, { headers: authHeader() });
    }


    updateFPotensi(item){
        return axios.put(API_URL + `updateFPotensi/${item.id}`, item, {headers: authHeader()})
    }
    createFPotensi(item){
        return axios.post(API_URL + `createFPotensi`, item, {headers: authHeader()})
    }
    deleteFPotensi(id){
        return axios.delete(API_URL + `deleteFPotensi/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFPotensi(itemIds){
        return axios.delete(API_URL + `deleteAllFPotensi`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FPotensiService()