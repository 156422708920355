import { render, staticRenderFns } from "./FPotensIKawasanUkuran.vue?vue&type=template&id=6083fd90&scoped=true"
import script from "./FPotensIKawasanUkuran.vue?vue&type=script&lang=js"
export * from "./FPotensIKawasanUkuran.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6083fd90",
  null
  
)

export default component.exports