<template>
  <div class="mt-4">
    <v-card>
      <v-card-title> SPASIAL PROJECT PLAN </v-card-title>
      <v-card-text>
        <v-container class="pa-2">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="itemsFileFiltered"
                :page.sync="currentPage"
                :items-per-page="pageSize"
                hide-default-footer
                class="elevation-0"
                @page-count="totalTablePages = totalPaginationPages"
              >
                <template v-slot:[`item.description`]="{ item }">
                  <span v-if="item.selected"
                    ><v-icon small color="success"
                      >mdi-check-circle</v-icon
                    ></span
                  >
                  {{ item.description }}
                </template>

                <template v-slot:[`item.fileName`]="{ item }">
                  <div class="caption">
                    <a :href="`${downloadFile(item)}`" target="_blank">
                      <v-icon color="red" small>mdi-download</v-icon>
                      {{ item.fileName }}
                    </a>
                  </div>
                </template>

                <template v-slot:[`item.remark1`]="{ item }">
                  <v-chip :color="item.remark1" small>
                    {{ item.remark1 }}
                  </v-chip>
                </template>
                <template v-slot:[`item.remark2`]="{ item }">
                  <v-chip :color="item.remark2" small>
                    {{ item.remark2 }}
                  </v-chip>
                </template>

                <template v-slot:[`item.modifiedBy`]="{ item }">
                  <div class="subtitle-2">
                    {{ item.modifiedBy }}
                  </div>
                  <div class="caption">
                    {{ formatCreatedDate(item.created) }}
                  </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn @click="showDialogEdit(item)" icon>
                    <v-icon small color="warning"> mdi-pencil </v-icon>
                  </v-btn>

                  <v-btn @click="deleteDialogShow(item)" icon>
                    <v-icon small color="red accent-4"> mdi-delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="text-center">
          <v-btn color="primary" block @click="showDialogUpload"
            ><v-icon color="red">mdi-upload</v-icon> Upload
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>

    <UploadSpasialDialog
      ref="refUploadDialog"
      @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadSpasialDialog>

    <FPotensiSpasialDialog
      ref="refFormDialog"
      :formMode="formMode"
      @eventFromFormDialogEdit="saveDataEdit"
    ></FPotensiSpasialDialog>

    <DeleteConfirmDialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>

    <v-snackbar v-model="snackbar">
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import FPotensiFileService from "@/services/apiservices/f-potensi-file-service";

import FPotensi from "@/models/f-potensi";
import UploadSpasialDialog from "@/components/utils/UploadSpasialDialog";
import FPotensiFile from "../../../models/f-potensi-file";
import DeleteConfirmDialog from "@/components/utils/DeleteConfirmDialog";

import { format, parseISO } from "date-fns";
import FormMode from "@/models/form-mode";
import FPotensiSpasialDialog from "@/components/admin_potensi/potensi/FPotensiSpasialDialog";

export default {
  name: "FPotensiSpasialMain",
  components: {
    FPotensiSpasialDialog,
    UploadSpasialDialog,
    DeleteConfirmDialog,
  },
  props: {
    itemModified: new FPotensi(),
    itemsFile: [],
  },
  data() {
    return {
      snackbar: false,
      snackBarMesage: "",

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 50,
      pageSizes: [10, 25, 50, 150, 500],

      search: "",
      headers: [
        {
          text: "Deskripsi",
          align: "start",
          sortable: true,
          value: "description",
          width: "25%",
        },
        { text: "Nama File", value: "fileName", width: "40%" },
        { text: "Line Color", value: "remark1", width: "10%" },
        { text: "Fill Color", value: "remark2", width: "10%" },
        { text: "Uploader", value: "modifiedBy", width: "20%" },
        { text: "Actions", value: "actions", width: "10%", sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
    };
  },
  computed: {
    itemsFileFiltered() {
      return this.itemsFile.filter(
        (item) => item.potensiType === 4 && item.fileType == "SPA"
      );
      // return this.itemsFile
    },
  },
  methods: {
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fsectorTypeBean !== 0 &&
        this.itemModified.fsubAreaBean !== 0 &&
        this.itemModified.fdivisionBean !== 0
      ) {
        // if (this.itemModified.id===0){
        // this.$emit('eventSaveItemWithoutClose', false)
        // console.log("masuk masih kosong")
        // }else {
        //   console.log("Sudah ada isinya")
        // }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi, Sektor dan Desa harus diisi dahulu";
        this.snackbar = true;
      }
    },
    completeUploadSuccess: function (val) {
      if (val.fileName !== "") {
        let fpotensiFileBean = new FPotensiFile(
          0,
          this.itemModified.id,
          val.fileName,
          val.description,
          "SPA",
          4,
          true,
          val.lineColor,
          val.fillColor
        );
        fpotensiFileBean.modifiedBy = this.$store.state.auth.user.username;

        FPotensiFileService.createFPotensiFile(fpotensiFileBean).then(
          (response) => {
            // console.log(response)
            this.$refs.refUploadDialog.closeDialog();
            //Just REfresh files
            this.$emit("retrieveFiles", response.dummy);
          },
          (error) => {
            console.log(error.message);
          }
        );
      }
    },
    downloadFile(item) {
      // console.log(item)
      this.itemSelectedIndex = this.itemsFile.indexOf(item);
      this.itemSelected = Object.assign({}, item);

      return FileService.file_url(item.fileName);
    },

    showDialogEdit(item) {
      this.itemSelectedIndex = this.itemsFile.indexOf(item);
      const itemModified = Object.assign({}, item);
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM;
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified);
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      console.log(this.itemSelectedIndex);
      try {
        Object.assign(
          this.itemsFile[this.itemSelectedIndex],
          this.itemSelected
        );
      } catch (e) {
        // this.fetchFPotensi()
        e.toString();
      }
      this.closeDialog();
    },

    deleteDialogShow(item) {
      // console.log(item)
      this.itemSelectedIndex = this.itemsFile.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.description
      );
    },

    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.itemsFile[this.itemSelectedIndex];
      FPotensiFileService.deleteFPotensiFile(deletedItem.id).then(
        () => {
          this.itemsFile.splice(this.itemSelectedIndex, 1);
          this.closeDialog();
        },
        (error) => {
          console.log(error);
          this.snackBarMesage = "gagal hapus (digunakan oleh data anakk)";
          this.snackbar = true;
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
      FileService.deleteFile(deletedItem.fileName).then(
        (response) => {
          console.log(response.data);
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      console.log(items);
    },
    closeDialog() {
      // this.myConfirmDialog = false
      this.formMode = "";
      this.$refs.refDeleteConfirmDialog.setDialogState(false);

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FPotensiFile());
        this.itemSelectedIndex = -1;
      });
    },

    formatCreatedDate(dateCreated) {
      return format(
        parseISO(new Date(dateCreated).toISOString()),
        "dd-MMM-yyyy hh:mm:ss"
      );
    },
  },
};
</script>

<style scoped>
</style>